/* eslint-disable camelcase */
import { request, requestJSON } from './request'
import { Topic, TopicField } from './topic'
import { Image } from './image'

// get project
export interface Owner {
  pk: number
  username: string
  forum_user: {
    avatar_url: string
    is_premium: boolean
  }
}

export interface ProjectRelease {
  pk: number
  name: string
  description: string
  release_order: number
  is_tagged?: boolean
}

export interface ProjectCustomLink {
  title: string
  url: string
}

export type DownloadStrategy =
  'custom_links' |
  'repo_release' |
  'git_ref_archive' |
  'project_release'

// export interface ProjectConfiguration {
//   version: string
//   download_behavior: {
//     active_strategy: DownloadStrategy
//     strategies: {
//       custom_links: {
//         links: ProjectCustomLink[]
//       }
//       repo_release: {
//         git_tag: null | string
//         include_sources: boolean
//         include_binaries: boolean
//       }
//       git_ref_archive: {
//         git_ref: string
//       }
//       project_release: {
//         ref: string
//       }
//     }
//   }
//   global_assets_meta: {
//     protected: boolean
//     protection_endpoint: string
//     protection_unlock_url: string
//   }
// }

// export const intialProjectConfig = (): ProjectConfiguration => ({
//   version: '',
//   download_behavior: {
//     active_strategy: 'custom_links',
//     strategies: {
//       custom_links: {
//         links: []
//       },
//       repo_release: {
//         git_tag: null,
//         include_sources: false,
//         include_binaries: false
//       },
//       git_ref_archive: {
//         git_ref: 'master'
//       },
//       project_release: {
//         ref: 'latest'
//       }
//     }
//   },
//   global_assets_meta: {
//     protected: false,
//     protection_endpoint: '',
//     protection_unlock_url: ''
//   }
// })

// export const cloneProjectConfig = (p: ProjectConfiguration): ProjectConfiguration => ({
//   version: p.version,
//   download_behavior: {
//     active_strategy: p.download_behavior !== undefined ? p.download_behavior.active_strategy : 'git_ref_archive',
//     strategies: {
//       custom_links: {
//         links: p.download_behavior !== undefined ? [ ...p.download_behavior.strategies.custom_links.links ] : []
//       },
//       repo_release: p.download_behavior !== undefined
//         ? {
//             ...p.download_behavior.strategies.repo_release
//           }
//         : {
//             git_tag: null,
//             include_sources: true,
//             include_binaries: true
//           },
//       git_ref_archive: p.download_behavior !== undefined
//         ? {
//             ...p.download_behavior.strategies.git_ref_archive
//           }
//         : {
//             git_ref: 'master'
//           },
//       project_release: p.download_behavior !== undefined
//         ? {
//             ...p.download_behavior.strategies.project_release
//           }
//         : {
//             ref: 'latest'
//           }
//     }
//   },
//   global_assets_meta: {
//     ...p.global_assets_meta
//   }
// })

export type ProjectStatus = 'published' | 'draft'

export type ProjectValidationStatus = 0 | 1 | 2 | 3

export interface Project {
  pk: number
  title: string
  description: string
  version: string
  banner_image: File
  banner_crop_data: string
  external_url: string
  readme_cms_content: string
  is_readme_in_repo: boolean
  is_premium: boolean
  is_protected: boolean
  protection_endpoint: string
  protection_unlock_url: string
  readme_edit_url: string
  slug: string
  status: ProjectStatus
  validation_status: ProjectValidationStatus
  topics: Topic[]
  // configuration: ProjectConfiguration
  active_strategy: string
  show_download_repo: boolean
  show_commits: boolean
  releases: ProjectRelease[]
  owner: Owner
  permissions: {
    edit: boolean
    delete: boolean
  }
}

export interface ProjectExternal {
  pk: number
  title: string
  description: string
  version: string
  banner_image: File
  banner_crop_data: string
  is_premium: boolean
  external_url: string
  external_git_repository: string
  readme_edit_url: string
  slug: string
  status: ProjectStatus
  validation_status: ProjectValidationStatus
  topics: Topic[]
  // configuration: ProjectConfiguration
  active_strategy: string
  show_download_repo: boolean
  show_commits: boolean
  releases: ProjectRelease[]
  owner: Owner
  permissions: {
    edit: boolean
    delete: boolean
  }
}

// get project repository
export interface Commit {
  url: string
  title: string
  created_at: string
}

export interface Tag {
  url: string
  name: string
  created_at: string
}

export interface Repository {
  url: string
  readme: {
    html: string
  }
  summary: {
    latest_tags: Tag[]
    latest_commits: Commit[]
  }
}

export type LinkType =
  'download' |
  'link'

// get project download
export interface Link {
  title: string
  subtitle?: string
  group?: string
  authorized_download: boolean
  url?: string
  protection_unlock_url?: string
  link_type?: LinkType
}

export interface Download {
  links: Link[]
  meta: {
    release_notes?: string
  }
}

interface Contributor {
  source: string
  extra_data: {
    commits?: number
    additions?: number
    deletions?: number
    access_level?: number
  }
  display_name: string
  oauth_id: string
  profile_url: string
  avatar_url: string
}

interface CollectionFeatured {
  id: number
  pk: number
  content_type?: string
  title: string
  description: string
  slug: string
  images: Image
  topics: Topic[]
}

interface Featured {
  featured_in: CollectionFeatured[]
}

export async function getProjects (): Promise<Project[]> {
  const url = new URL('/api/projects/', window.location.href)
  return await requestJSON(url.toString())
}

export async function getProject (projectId: number): Promise<Project> {
  const url = new URL(`/api/projects/${projectId}/`, window.location.href)
  return await requestJSON(url.toString())
}

export async function getProjectLight (projectId: number): Promise<Project> {
  const url = new URL(`/api/projects-light/${projectId}/`, window.location.href)
  return await requestJSON(url.toString())
}

export async function getProjectAbortable (projectId: number, controller: AbortController): Promise<Project> {
  const url = new URL(`/api/projects/${projectId}/`, window.location.href)
  return await requestJSON(url.toString(), { signal: controller.signal })
}

export async function getProjectLightAbortable (projectId: number, controller: AbortController): Promise<Project> {
  const url = new URL(`/api/projects-light/${projectId}/`, window.location.href)
  return await requestJSON(url.toString(), { signal: controller.signal })
}

export async function getProjectExternal (projectId: number): Promise<ProjectExternal> {
  const url = new URL(`/api/projects/${projectId}/`, window.location.href)
  return await requestJSON(url.toString())
}

export async function getProjectRepo (projectId: number, checkLength = true): Promise<Repository | undefined> {
  const url = new URL(`/api/projects/${projectId}/repositories/`, window.location.href)
  interface Resp { repositories: Repository[] }
  const { repositories } = await requestJSON<Resp>(url.toString())
  console.log('repositories: ', repositories)
  // eslint-disable-next-line
  if (repositories) {
    // if ((repositories.length !== 1) && checkLength) {
    //   throw new Error(`unexpected repositories length: ${repositories.length}`)
    // }
    if (repositories.length === 0) {
      return undefined
    }
    return repositories[0]
  }
  return undefined
}

// export async function getDefaultProjectConfig (): Promise<ProjectConfiguration> {
//   const url = new URL('/api/projects/default_configuration/', window.location.href)
//   return await requestJSON(url.toString())
// }

export async function getProjectContributors (projectId: number, limit?: number): Promise<Contributor[]> {
  let { contributors } = await requestJSON<{ contributors: Contributor[] | null }>(
    `/api/projects/${projectId}/contributors/`
  )
  if (contributors === null) {
    return []
  }
  // remove contributors without display_name, oauth_id (e.g. github repo)
  contributors = contributors.filter(c => c.display_name !== null && c.oauth_id !== null)
  if (limit !== undefined) {
    contributors = contributors.splice(0, limit)
  }
  // https://stackoverflow.com/a/56757215
  const unique = contributors.filter((v, i, a) => a.findIndex(t => (t.oauth_id === v.oauth_id)) === i)
  return unique
}

export async function getProjectDownload (projectId: number): Promise<Download> {
  return await requestJSON(`/api/projects/${projectId}/download/`)
}

export async function getProjectCustomDownloadLinks (projectId: number): Promise<ProjectCustomLink[]> {
  return await requestJSON(`/api/projects/${projectId}/custom_download_links/`)
}

export async function getProjectFeaturedIn (projectId: number): Promise<Featured> {
  return await requestJSON(`/api/projects/${projectId}/featured_in/`)
}

export async function getProjectTaggedVersion (projectId: number): Promise<ProjectRelease[]> {
  const url = new URL(`/api/projects/${projectId}/`, window.location.href)

  const project: Project = await requestJSON(url.toString())
  const tagged = project.releases.filter((r) => r.is_tagged === true)

  return tagged
}

interface Post {
  title: string
  url: string
  created_at: string
  bumped_at: string
  reply_count: number
  discourse_user_id: number
  forum_user_id: number
  username: string
  user_avatar_url: string
  user_is_premium: boolean
}

interface DiscussionRoom {
  url: string
  summary: {
    latest_posts: Post[]
    unanswered_posts: Post[]
  }
}

export async function getGeneralDiscussion (): Promise<DiscussionRoom> {
  const resp = await requestJSON<{ discussion_rooms: DiscussionRoom[] }>(
    '/api/misc/general-discussion/'
  )
  if (resp.discussion_rooms.length !== 1) {
    throw new Error(`unexpected discussion_rooms length: ${resp.discussion_rooms.length}`)
  }
  return resp.discussion_rooms[0]
}

export async function getProjectDiscussion (projectId: number): Promise<DiscussionRoom> {
  const resp = await requestJSON<{ discussion_rooms: DiscussionRoom[] }>(
    `/api/projects/${projectId}/discussion/`
  )
  if (resp.discussion_rooms.length !== 1) {
    throw new Error(`unexpected discussion_rooms length: ${resp.discussion_rooms.length}`)
  }
  return resp.discussion_rooms[0]
}

interface BaseCreateProject {
  status: string
  title: string
  description: string
  banner_image?: File
  banner_crop_data?: string
  topics: TopicField[]
  // configuration: ProjectConfiguration
}
interface CreateProjectInternal extends BaseCreateProject {
  is_readme_in_repo: boolean
  readme_cms_content?: string
  readme_content?: string
  is_premium?: boolean
  is_protected?: boolean
  protection_endpoint?: string
  protection_unlock_url?: string
}
interface CreateProjectExternal extends BaseCreateProject {
  external_git_repository: string
}
type CreateProjectReq = CreateProjectInternal | CreateProjectExternal

interface CreateProjectResp {
  forum_project_url: string
  forum_project_id: string
  repository_url: string
  discussion_id: string
}

export async function createProject (body: CreateProjectReq): Promise<CreateProjectResp> {
  return await requestJSON('/api/projects/', { method: 'POST', body })
}

export interface EditProjectReq {
  title: string
  description: string
  topics: TopicField[]
  // configuration: ProjectConfiguration
  active_strategy?: string
  show_download_repo?: boolean
  show_commits?: boolean
  status: ProjectStatus
  external_url: string
  external_git_repository?: string
  readme_cms_content: string
  is_premium?: boolean
  is_protected?: boolean
  protection_endpoint?: string
  protection_unlock_url?: string
}

export interface EditProjectResp {
  forum_project_url: string
  forum_project_id: string
}

export async function editProject (projectId: number, body: EditProjectReq): Promise<EditProjectResp> {
  return await requestJSON(`/api/projects/${projectId}/`, { method: 'PUT', body })
}

export interface EditProjectExternalUrlReq {
  title: string
  description: string
  topics: TopicField[]
  // configuration: ProjectConfiguration
  status: ProjectStatus
  external_url: string
  external_git_repository?: string
}

export async function editProjectExternalUrl (projectId: number, body: EditProjectExternalUrlReq): Promise<EditProjectResp> {
  return await requestJSON(`/api/projects/${projectId}/`, { method: 'PUT', body })
}

export async function deleteProject (projectId: number): Promise<void> {
  await request(`/api/projects/${projectId}/`, { method: 'DELETE' })
}

export interface DeleteProjectImageReq {
  title: string
  description: string
  banner_image: File | null
  banner_crop_data: string
}

export async function deleteProjectImage (projectId: number, body: DeleteProjectImageReq): Promise<EditProjectResp> {
  return await requestJSON(`/api/projects/${projectId}/`, { method: 'PUT', body })
}
